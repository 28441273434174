import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServiceproviderComponent } from './serviceprovider/serviceprovider.component';
import { LoginComponent } from '../app/login/login.component';
import { PagenotfoundComponent } from '../app/pages/error/pagenotfound/pagenotfound.component';
import { ServicePageComponent } from './pages/service-page/service-page.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Nanny', component: ServiceproviderComponent },
  { path: 'login', component: LoginComponent },
  { path: 'service', component: ServicePageComponent },





  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
