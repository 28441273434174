
import { Component, OnInit, NgModule, VERSION, ViewChild, ElementRef, Inject } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-serviceprovider',
  templateUrl: './serviceprovider.component.html',
  styleUrls: ['./serviceprovider.component.css']
})
export class ServiceproviderComponent implements OnInit {




  constructor() { }

  ngOnInit() {
  }

}