import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServiceproviderComponent } from './serviceprovider/serviceprovider.component';
import { LoginComponent } from './login/login.component';
import { HomeHeaderComponent } from './pages/header/home-header/home-header.component';
import { ServiceHeaderComponent } from './pages/header/service-header/service-header.component';
import { HomeFooterComponent } from './pages/footer/home-footer/home-footer.component';
import { PagenotfoundComponent } from './pages/error/pagenotfound/pagenotfound.component';
import { ServicePageComponent } from './pages/service-page/service-page.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServiceproviderComponent,
    LoginComponent,
    HomeHeaderComponent,
    ServiceHeaderComponent,
    HomeFooterComponent,
    PagenotfoundComponent,
    ServicePageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
