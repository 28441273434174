import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {

  constructor(public routes: Router) { }

  ngOnInit() {
  }
  becomeNanny() {
    this.routes.navigate(['Nanny']);
  }
  loginPage() {
    this.routes.navigate(['login']);
  }
}
